// tintData.js
const TintData = [
  {
    color: "Brown",
    tints: [
      { percentage: "10%", bgColor: "bg-brown10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-brown15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-brown30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-brown60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-brown75", textColor: "text-slate-100" },
      { percentage: "85%", bgColor: "bg-brown85", textColor: "text-slate-100" },
      { percentage: "94%", bgColor: "bg-brown94", textColor: "text-slate-100" },
    ],
    description:
      "Brown lenses enhance contrast and depth perception, making them ideal for activities where judging distance is crucial. They reduce glare and improve clarity in variable light conditions, such as during overcast or partly cloudy days. Perfect for outdoor sports, driving, and everyday wear, brown lenses provide a warm, soothing tint that reduces eye strain.",
  },
  {
    color: "Grey",
    tints: [
      { percentage: "10%", bgColor: "bg-grey10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-grey15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-grey30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-grey60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-grey75", textColor: "text-slate-100" },
      { percentage: "85%", bgColor: "bg-grey85", textColor: "text-slate-100" },
      { percentage: "94%", bgColor: "bg-grey94", textColor: "text-slate-100" },
    ],
    description:
      "Grey lenses offer true color perception by reducing overall brightness while preserving natural color balance. They are excellent for bright and sunny conditions, minimizing glare without distorting colors. Grey lenses are versatile and ideal for driving, outdoor sports, and general use, ensuring comfort and protection in intense sunlight.",
  },
  {
    color: "Grey Green",
    tints: [
      {
        percentage: "10%",
        bgColor: "bg-greygreen10",
        textColor: "text-slate-600",
      },
      {
        percentage: "15%",
        bgColor: "bg-greygreen15",
        textColor: "text-slate-600",
      },
      {
        percentage: "30%",
        bgColor: "bg-greygreen30",
        textColor: "text-slate-600",
      },
      {
        percentage: "60%",
        bgColor: "bg-greygreen60",
        textColor: "text-slate-100",
      },
      {
        percentage: "75%",
        bgColor: "bg-greygreen75",
        textColor: "text-slate-100",
      },
      {
        percentage: "85%",
        bgColor: "bg-greygreen85",
        textColor: "text-slate-100",
      },
      {
        percentage: "94%",
        bgColor: "bg-greygreen94",
        textColor: "text-slate-100",
      },
    ],
    description:
      "Grey-Green lenses combine the best features of grey and green tints, providing excellent color accuracy and contrast enhancement. They reduce glare and block harmful blue light, making them perfect for outdoor activities in varying light conditions. These lenses are particularly favored for their soothing effect on the eyes, ensuring visual comfort during prolonged use.",
  },
  {
    color: "Graphite Green",
    tints: [
      {
        percentage: "10%",
        bgColor: "bg-graphitegreen10",
        textColor: "text-slate-600",
      },
      {
        percentage: "15%",
        bgColor: "bg-graphitegreen15",
        textColor: "text-slate-600",
      },
      {
        percentage: "30%",
        bgColor: "bg-graphitegreen30",
        textColor: "text-slate-600",
      },
      {
        percentage: "60%",
        bgColor: "bg-graphitegreen60",
        textColor: "text-slate-100",
      },
      {
        percentage: "75%",
        bgColor: "bg-graphitegreen75",
        textColor: "text-slate-100",
      },
      {
        percentage: "85%",
        bgColor: "bg-graphitegreen85",
        textColor: "text-slate-100",
      },
    ],
    description:
      "Graphite-Green lenses offer a unique blend of style and performance. They provide exceptional contrast and color accuracy while reducing glare and enhancing visual clarity. Ideal for both bright and variable light conditions, these lenses are perfect for driving, outdoor sports, and everyday wear. The graphite-green tint ensures a comfortable viewing experience, reducing eye strain and enhancing visual appeal.",
  },
  {
    color: "Blue",
    tints: [
      { percentage: "10%", bgColor: "bg-blue10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-blue15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-blue30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-blue60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-blue75", textColor: "text-slate-100" },
    ],
    description:
      "Blue lenses are designed to reduce glare from reflective surfaces like water and snow, making them ideal for activities such as skiing, snowboarding, and fishing. They enhance color perception and improve visual clarity in bright, sunny conditions. Blue lenses provide a cool, soothing tint that reduces eye fatigue and strain, making them perfect for prolonged outdoor use. Stylish and functional, these lenses are great for both sports and casual wear.",
  },
];

export default TintData;
