import SpatialVision from "../../assets/images/clearviz-lenses/lenstechnologies/Spatial Vision.png";
import EyeFocusProfiling from "../../assets/images/clearviz-lenses/lenstechnologies/Eye Focus Profiling.png";
import VisualStability from "../../assets/images/clearviz-lenses/lenstechnologies/Vision Stability.png";
import FitConfiguration from "../../assets/images/clearviz-lenses/lenstechnologies/Fit Configuration.png";
import RayTracing from "../../assets/images/clearviz-lenses/lenstechnologies/Ray Tracing.png";

const RaptuveLensTechnologiesData = [
  {
    iconSrc: SpatialVision,
    title: "Spatial Vision",
    description: "Naturally good vision, even when you’re on the move.",
  },
  {
    iconSrc: EyeFocusProfiling,
    title: "Eye Focus Profile",
    description: "Flawless vision from any angle.",
  },
  {
    iconSrc: VisualStability,
    title: "Visual Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    iconSrc: RayTracing,
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
];
const RaptuveWearerDetailsData = [
  {
    title: "Ideal Wearer",
    description: [
      {
        id: 1,
        desc: "Experienced progressive wearers looking for high-quality lenses.",
      },
      {
        id: 2,
        desc: "New progressive wearers searching for an easy-to-adapt option.",
      },
      {
        id: 3,
        desc: "Wearers who have a demand for wider visual fields in the intermediate zone.",
      },
    ],
  },
  {
    title: "Benefits",
    description: [
      {
        id: 1,
        desc: "Available for all types of prescriptions and additional powers.",
      },
      {
        id: 2,
        desc: "Wide and balanced visual areas.",
      },
      {
        id: 3,
        desc: "Good visual quality for all the zones and optimized for intermediate zone.",
      },
      {
        id: 4,
        desc: "Reduced swim effect and peripheral blur.",
      },
      {
        id: 5,
        desc: "Easy to adapt.",
      },
      {
        id: 6,
        desc: "Highly comfortable, even during digital device usage.",
      },
      {
        id: 7,
        desc: "Thinner lenses.",
      },
    ],
  },
  {
    title: "MFH (Minimum Fitting Height)",
    description: [
      {
        id: 1,
        desc: "14 mm | 15 mm | 16 mm | 17 mm | 18 mm",
      },
    ],
  },
  {
    title: "Personalization",
    description: [
      {
        id: 1,
        desc: "Fully Personalized Progressive Lens",
      },
    ],
  },
];

const VViewLensTechnologiesData = [
  {
    iconSrc: VisualStability,
    title: "Visual Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    iconSrc: RayTracing,
    title: "Ray Tracing",
    description:
      "Precise vision at every distance and in every direction of gaze.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
];
const VViewWearerDetailsData = [
  {
    title: "Ideal Wearer",
    description: [
      {
        id: 1,
        desc: "Progressive wearers who have a high usage of digital devices for long hours and are looking for quality and comfort.",
      },
    ],
  },
  {
    title: "Benefits",
    description: [
      {
        id: 1,
        desc: "Good vision and comfort during the usage of digital devices.",
      },
      {
        id: 2,
        desc: "Smooth transition between the near and intermediate zone.",
      },
      {
        id: 3,
        desc: "Good and wide near and distance vision.",
      },
    ],
  },
  {
    title: "MFH (Minimum Fitting Height)",
    description: [
      {
        id: 1,
        desc: "14 mm | 16 mm | 18 mm",
      },
    ],
  },
  {
    title: "Personalization",
    description: [
      {
        id: 1,
        desc: "Fully Personalized Progressive Lens",
      },
    ],
  },
];

const VisiStyleLensTechnologiesData = [
  {
    iconSrc: VisualStability,
    title: "Visual Stability",
    description: "Reduces the swim effect when moving.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Individual Fit Configuration",
    description: "Optimizes the vision zones based on the unique wearer.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Frame Fit Configuration",
    description: "Optimizes lens performance in any frame.",
  },
  {
    iconSrc: FitConfiguration,
    title: "Lens Fit Configuration",
    description: "Optimizes the aesthetic appearance of the lens.",
  },
];
const VisiStyleWearerDetailsData = [
  {
    title: "Ideal Wearer",
    description: [
      {
        id: 1,
        desc: "Solution for progressive lens wearers; those who are searching for effective and comfortable vision.",
      },
    ],
  },
  {
    title: "Benefits",
    description: [
      {
        id: 1,
        desc: "Good performance in all visual fields.",
      },
      {
        id: 2,
        desc: "Reduced swim effect.",
      },
      {
        id: 3,
        desc: "Comfortable and easy to adapt.",
      },
    ],
  },
  {
    title: "MFH (Minimum Fitting Height)",
    description: [
      {
        id: 1,
        desc: "14 mm | 16 mm | 18 mm",
      },
    ],
  },
];

const VisiFlexLensTechnologiesData = [
  {
    iconSrc: VisualStability,
    title: "Visual Stability",
    description: "Reduces the swim effect when moving.",
  },
];
const VisiFlexWearerDetailsData = [
  {
    title: "Ideal Wearer",
    description: [
      {
        id: 1,
        desc: "Experienced or progressive wearers looking for balanced visual fields.",
      },
    ],
  },
  {
    title: "Benefits",
    description: [
      {
        id: 1,
        desc: "Well-balanced foundational lens.",
      },
      {
        id: 2,
        desc: "Expansive near and far visual fields.",
      },
      {
        id: 3,
        desc: "Effective performance for regular use.",
      },
    ],
  },
  {
    title: "MFH (Minimum Fitting Height)",
    description: [
      {
        id: 1,
        desc: "14 mm | 16 mm | 18 mm",
      },
    ],
  },
];

export {
  RaptuveLensTechnologiesData,
  RaptuveWearerDetailsData,
  VViewLensTechnologiesData,
  VViewWearerDetailsData,
  VisiStyleLensTechnologiesData,
  VisiStyleWearerDetailsData,
  VisiFlexLensTechnologiesData,
  VisiFlexWearerDetailsData,
};
