import { ImageTextLayout, SEO } from "../components";

import BecomePartner from "../assets/images/home/becomepartner.webp";
import Careers from "../assets/images/home/careers.webp";
import ContactusPageBanner from "../../src/assets/images/contactus/headerImg.webp";
import { NavigationStrings } from "../constants";
import React from "react";
import StoreLocator from "../assets/images/home/store-locator.webp";
import UrlConstants from "../constants/urlConstants";

const ContactUs: React.FC = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.CONTACTUS);
  const sendMailTo = (receiver: string) => {
    const mailToText = `mailto:${receiver}`;
    window.location.href = mailToText;
  };

  const openDialPad = (number: string) => {
    const callTo = `tel:${number}`;
    window.location.href = callTo;
  };
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Contact Clearviz | Customer Support and Business Inquiries",
    "description": "Reach out to Clearviz for customer support, business inquiries, or partnership opportunities. Contact us for the best lens solutions.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Contact Clearviz | Customer Support and Business Inquiries"
        description="Reach out to Clearviz for customer support, business inquiries, or partnership opportunities. Contact us for the best lens solutions."
        keywords="Clearviz, contact, customer support, business inquiries, partnership, eyewear, lens manufacturer, Hyderabad"
        name="Clearviz Contact"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />

      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={ContactusPageBanner}
          alt="Contact us banner"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 content-section space-y-4 px-custom-padding flex flex-col">
        <div className="text-2xl md:text-3xl text-primary font-bold">
          Contact Us
        </div>
        <div className="text-base">
          <p>
            We value our customers and are committed to providing exceptional
            before- and after- sales service. If you have any questions or
            concerns, please don't hesitate to contact us.
          </p>
        </div>
      </div>
      <div className="content-section px-custom-padding font-semibold">
        Here's how you can reach us:
      </div>
      <div className="content-section py-4 px-custom-padding md:px-10 flex flex-col md:flex-row justify-between h-full">
        <div className="flex flex-col w-full md:w-1/2">
          <div className="mb-8">
            <div className="text-lg font-bold mb-2 text-black">
              Sales Office:
            </div>
            <div className="mb-4">
              <a
                href="https://goo.gl/maps/5NiuNHqx9g7Abw918"
                target="_blank"
                rel="noreferrer"
                className="flex-row space-y-[0.15rem] transition-all"
              >
                <div>Eyegear Optics India Pvt. Ltd.,</div>{" "}
                <div>No 7-1-69/1/25/11 & 13, Above Urbankisaan store,</div>
                <div>Dharam Karan Road, Ameerpet,</div>{" "}
                <div>Hyderabad - 500082, Telangana.</div>
                <div>
                  Tel:{" "}
                  <span
                    className="email-text"
                    onClick={() => openDialPad("040 - 46461111")}
                  >
                    040 - 46461111
                  </span>{" "}
                  ,{" "}
                  <span
                    className="email-text"
                    onClick={() => openDialPad("040 - 42071111")}
                  >
                    040 - 42071111
                  </span>{" "}
                </div>
              </a>
            </div>
            <div className="text-lg font-bold mb-2 text-black">
              Customer Service:
            </div>
            <div className="mb-4">
              <div
                className="flex gap-2"
                onClick={() => sendMailTo("cs.lens@eyegear.com")}
              >
                <span>Email : </span>
                <span className="text-blue-500 cursor-pointer">
                  cs.lens@eyegear.com
                </span>
              </div>
              <div
                className="flex gap-2"
                onClick={() => openDialPad("+91 - 8106165888")}
              >
                <span>Mobile No : </span>
                <span className="text-blue-500 cursor-pointer">
                  +91 - 8106165888
                </span>
              </div>
              <div className="flex gap-2">
                <div>Timings:</div>
                <div className="text-primary font-semibold text-[15px]">
                  <div>10:00 am to 09:00 pm Working Days</div>
                  <div>10:00 am to 06:00 pm Sundays & Holidays</div>
                </div>
              </div>
            </div>
            <div className="text-lg font-bold mb-2 text-black">
              Business Enquiries:
            </div>
            <div className="mb-4">
              <div
                className="flex gap-2"
                onClick={() => sendMailTo("corporate@eyegear.com")}
              >
                <span>Email : </span>
                <span className="text-blue-500 cursor-pointer">
                  corporate@eyegear.com
                </span>
              </div>
              <div
                className="flex gap-2"
                onClick={() => openDialPad("+91 - 7799777830")}
              >
                <span>Contact No : </span>
                <span className="text-blue-500 cursor-pointer">
                  +91 - 7799777830
                </span>
              </div>
            </div>
            <div className="text-lg font-bold mb-2 text-black">Careers:</div>
            <div
              className="flex gap-2"
              onClick={() => sendMailTo("careers@eyegear.com")}
            >
              <span>Email : </span>
              <span className="text-blue-500 cursor-pointer">
                careers@eyegear.com
              </span>
            </div>
          </div>
        </div>
        <div className="border-2 content-page-section w-full md:w-1/2">
          <iframe
            title="EYEGEAR"
            width="600"
            height="400"
            className="w-full h-64 md:h-full"
            src="https://www.google.com/maps/d/embed?mid=1xByLH3isARgTQ_N_DsOaMeOmAYzhM_k&hl=en&ehbc=2E312F"
          ></iframe>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clearviz Partner"
        description="As the leading manufacturer of spectacle lenses, Clearviz offers unparalleled quality, 
        innovation, and support to our business partners. By collaborating with us, you gain access to an 
        extensive range of premium lenses, cutting-edge technology, and expert insights, empowering you to 
        meet the diverse needs of your customers."
        description2="Experience the advantages of partnering with Clearviz and grow your business with 
        confidence. Join us today and achieve excellence together"
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clearviz"
        description="Join a dynamic and innovative eyewear company that values its employees and is 
        committed to fostering a positive, inclusive work environment. If you are passionate about eyewear 
        and seeking an exciting, upwardly mobile career opportunity, we invite you to apply!"
        buttonTxt="Send your CV"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ContactUs;
