import { ImageTextLayout, SEO } from "../../components";
import {
  VViewLensTechnologiesData,
  VViewWearerDetailsData,
} from "./LensDetails";

import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/vview/headerImg.webp";
import { NavigationStrings } from "../../constants";
import React from "react";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import UrlConstants from "../../constants/urlConstants";
import VView10 from "../../assets/images/clearviz-lenses/lens-portfolio/vview/V View 10.webp";
import VView20 from "../../assets/images/clearviz-lenses/lens-portfolio/vview/V View 20.webp";

const ClearvizVView = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.VVIEW);
  const twoColumnMap = VViewLensTechnologiesData.reduce((acc, item, index) => {
    if (index % 2 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, [] as Array<typeof VViewLensTechnologiesData>).map(([first, second]) => ({
    first,
    second,
  }));
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz V View Lenses | Progressive Lenses for the Digital Age",
    "description": "Explore Clearviz V View lenses, designed for progressive wearers navigating the digital age. Offering smooth transitions between near and intermediate view, tailored for your modern lifestyle.",
    "url": UrlLink
  };  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="near and intermediate view lenses by Cleariviz V View Lenses"
        description="Experience seamless transitions between near and intermediate views with Clariviz V View. Designed for digital life, our innovative lens technology provides smoother focus and reduced eye strain"
        keywords="Clearviz V View digital lenses for computer users, Best progressive lenses for near and intermediate vision, Seamless transition lenses for digital device users, Eye care solutions for digital eye strain, Advanced presbyopia correction lenses, Near and Intermediate Vision, Intermediate Vision Correction, Presbyopia Correction, Progressive Lenses, Digital Lens Technology"
        name="Clearviz V View Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz V View
        </h1>
        <p className="text-base text-black leading-loose w-full md:w-[98%]">
          These lenses were developed to attend to the needs of progressive
          wearers who are looking for a good option and spend part of their days
          switching their focus from digital devices to objects on the distance
          zone. Developed especially for digital life, Clearviz V View provides
          a smoother and quicker transition between the near and intermediate
          view.
        </p>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 gap-8 md:grid-cols-2 place-items-center">
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Clearviz V View 2.0
          </h3>
          <div>
            <img
              src={VView20}
              alt="Clearviz V View 2.0"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Clearviz V View 1.0
          </h3>
          <div>
            <img
              src={VView10}
              alt="Clearviz V View 1.0"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
      </div>

      {/* technologies & ideal wear */}
      <div className="py-8 px-custom-padding">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="md:border-r md:pr-8">
            {VViewWearerDetailsData.map((item, index) => (
              <div key={index} className="grid grid-cols-1 mb-8 gap-3">
                <div className="text-2xl font-bold text-primary">
                  {item.title}
                </div>
                <ul className="flex flex-col gap-2">
                  {item.description.map((details) => (
                    <li
                      key={details.id}
                      className="list-disc tracking-wide ml-4"
                    >
                      {details.desc}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="md:pl-8 md:py-4">
            <h2 className="pb-6 text-3xl text-primary font-bold">
              Clearviz V View Technologies
            </h2>
            {twoColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 small:grid-cols-2 ${
                  index % 2 !== 0 ? "small:bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border"
                              : "bg-slate-400/10 small:bg-transparent border-l border-r border-b small:border-t small:border-r small:border-b small:border-l-0"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-l border-r border-b small:border-l-0 bg-slate-400/10 small:bg-transparent"
                        } 
                        p-[0.85rem]
                        grid grid-cols-1
                        `}
                      >
                        <div className="flex items-center gap-2">
                          <div className="w-1/4 md:w-1/3">
                            <img
                              src={item.iconSrc}
                              alt={item.title}
                              className="w-[100%] h-[100%]"
                            />
                          </div>
                          <h3 className="w-3/4 md:w-2/3 text-tinybase md:text-base font-bold text-primary">
                            {item.title}
                          </h3>
                        </div>
                        <div className="py-2">
                          <p className="text-black text-[0.75rem] xs:text-[0.85rem] small:text-tinybase md:text-[1rem]">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizVView;
