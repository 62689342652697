import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/office/headerImg.webp";
import Office from "../../assets/images/clearviz-lenses/lens-portfolio/office/ClearVizOffice.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const ClearvizOffice = () => {
  const UrlLink = UrlConstants.base_url.concat(
    NavigationStrings.LensPortfolio.OFFICE
  );
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clearviz Office Lenses | Varifocal Vision for Professionals",
    description:
      "Clearviz Office lenses provide tailored varifocal vision correction for professionals, offering enhanced near and intermediate vision for extended working hours.",
    url: UrlLink,
  };

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Office Lenses | Varifocal Vision for Professionals"
        description="Clearviz Office lenses provide tailored varifocal vision correction for professionals, offering enhanced near and intermediate vision for extended working hours."
        keywords="Clearviz Office, varifocal lenses, vision correction, near vision, intermediate vision, office lenses, professional lenses"
        name="Clearviz Office Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />

      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Office
        </h1>
        <p className="text-base text-black leading-loose w-full md:w-[98%]">
          Clearviz Office is a versatile solution for professionals who need
          varifocal vision correction tailored to their specific working
          environment. With configurations that enhance near and intermediate
          vision, these lenses can be worn by remote workers, office workers,
          health professionals, industrial workers, and many other
          professionals.
        </p>
      </div>

      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 place-items-center">
        <div>
          <img
            src={Office}
            alt="Clearviz Office"
            className="w-[100%] h-[100%]"
          />
        </div>
      </div>

      {/* 3 Column */}
      <div className="py-8 px-custom-padding w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-bold text-primary">Ideal Wearer</p>
          <ul className="w-full md:w-[90%] flex flex-col gap-4">
            <li className="list-disc tracking-wide ml-4 text-base">
              Presbyopes who have an extended usage of the near and intermediate
              vision, especially during working hours.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              New presbyopes.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-bold text-primary">Benefits</p>
          <ul className="w-[90%] flex flex-col gap-4">
            <li className="list-disc tracking-wide ml-4 text-base">
              Peak vision for intermediate and near distances, including during
              the use of digital devices.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Enhanced postural ergonomics, minimizing unnecessary head
              movements.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              A comfortable option for digital device usage.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              The smooth and dynamic transition between the near and the
              intermediate zones.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Easy adaptation.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Elimination of peripheral blur.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Available for all types of prescriptions and addition powers.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-full flex flex-col gap-4">
              <li className=" list-disc ml-2 text-base text-black w-full">
                <div>14 mm | 18 mm</div>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">Personalization</p>
            <ul>
              <li className="list-disc ml-2 text-base text-black w-full md:w-[98%]">
                Personalized Progressive Lens
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizOffice;
