import BecomePartner from "../assets/images/home/becomepartner.webp";
import Careers from "../assets/images/home/careers.webp";
import HeaderImg from "../assets/images/about-us/manufacturing-unit/headerImg.webp";
import Raptuve from "../assets/images/home/raptuve.webp";
import { ImageTextLayout } from "../components";
import React from "react";

const LensManufacturingUnit = () => {
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="pt-8 flex flex-col px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Clearviz Lens Manufacturing Unit
          </h1>
          <p className="mt-8 tracking-wide text-lg">
            Watch the video to learn more about why Clearviz is possibly the
            best lens option for you
          </p>
          <div
            className="my-12 mx-18 w-full h-[40vh] xs:h-[50vh] small:h-[55vh] md:h-[65vh] 
        bg-[#CCCCCC] rounded-md flex items-center justify-center text-primary font-medium text-md"
          >
            Video Container
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={Raptuve}
        subtitle="Clearviz Raptvue"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clearviz Raptvue Lenses are made to fit the modern and 
        high-demand lifestyle of nowadays, where progressive wearers have a busy 
        schedule of work, leisure, indoor and outdoor activities."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clearviz Partner"
        description="As the leading manufacturer of spectacle lenses, Clearviz offers unparalleled quality, 
        innovation, and support to our business partners. By collaborating with us, you gain access to an 
        extensive range of premium lenses, cutting-edge technology, and expert insights, empowering you to 
        meet the diverse needs of your customers."
        description2="Experience the advantages of partnering with Clearviz and grow your business with 
        confidence. Join us today and achieve excellence together"
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clearviz"
        description="Join a dynamic and innovative eyewear company that values its employees and is 
        committed to fostering a positive, inclusive work environment. If you are passionate about eyewear 
        and seeking an exciting, upwardly mobile career opportunity, we invite you to apply!"
        buttonTxt="Send your CV"
        reverse
      />
    </section>
  );
};

export default LensManufacturingUnit;
