import { ImageTextLayout, SEO } from "../../components";
import {
  VisiFlexLensTechnologiesData,
  VisiFlexWearerDetailsData,
} from "./LensDetails";

import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/visiflex/headerImg.webp";
import { NavigationStrings } from "../../constants";
import React from "react";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import UrlConstants from "../../constants/urlConstants";
import visiFlex10 from "../../assets/images/clearviz-lenses/lens-portfolio/visiflex/Visi Flex 10.webp";
import visiFlex20 from "../../assets/images/clearviz-lenses/lens-portfolio/visiflex/Visi Flex 20.webp";

const ClearvizVisiFlex = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.VISIFLEX);
  const twoColumnMap = VisiFlexLensTechnologiesData.reduce(
    (acc, item, index) => {
      if (index % 2 === 0) {
        acc.push([item]);
      } else {
        acc[acc.length - 1].push(item);
      }
      return acc;
    },
    [] as Array<typeof VisiFlexLensTechnologiesData>
  ).map(([first, second]) => ({
    first,
    second,
  }));
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Visi Flex Lenses | Balanced Vision for Progressive Wearers",
    "description": "Explore Clearviz Visi Flex lenses, designed for experienced progressive wearers seeking comfort and balanced visual distances in the near, intermediate, and distance zones.",
    "url": UrlLink
  };
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Balanced Progressive Lenses for Comfort|Clearviz Visi Flex"
        description="Discover Clearviz Visi Flex, a comfortable progressive lens design for experienced wearers. Enjoy balanced visual distances in near, intermediate, and distance zones. Upgrade your vision today!"
        keywords="Balanced visual distance correction eyewear, Comfortable progressive lenses for daily wear, Standard progressive design for experienced wearers, Digital progressive lenses for intermediate vision, Balanced Vision, Visual Distance Correction, Comfortable Eyewear, Standard Progressive Design"
        name="Clearviz Visi Flex Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Visi Flex
        </h1>
        <p className="text-base text-black leading-loose w-full md:w-[98%]">
          A digital design for experienced progressive wearers who want a good
          option with balanced visual distances. Clearviz Visi Flex is a
          standard and comfortable design that can satisfy the wearer in the
          near, intermediate, and distance zones.
        </p>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 gap-8 md:grid-cols-2 place-items-center">
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Clearviz Visi Flex 2.0
          </h3>
          <div>
            <img
              src={visiFlex20}
              alt="Visi Flex 2.0"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Clearviz Visi Flex 1.0
          </h3>
          <div>
            <img
              src={visiFlex10}
              alt="Visi Flex 1.0"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
      </div>
      {/* technologies & ideal wear */}
      <div className="py-8 px-custom-padding">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="md:border-r md:pr-8">
            {VisiFlexWearerDetailsData.map((item, index) => (
              <div key={index} className="grid grid-cols-1 mb-8 gap-3">
                <div className="text-2xl font-bold text-primary">
                  {item.title}
                </div>
                <ul className="flex flex-col gap-2">
                  {item.description.map((details) => (
                    <li
                      key={details.id}
                      className="list-disc tracking-wide ml-4"
                    >
                      {details.desc}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="md:pl-8 md:py-4">
            <h2 className="pb-6 text-3xl text-primary font-bold">
              Clearviz Visi Flex Technologies
            </h2>
            {twoColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 small:grid-cols-2 ${
                  index % 2 !== 0 ? "small:bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border"
                              : "bg-slate-400/10 small:bg-transparent border-l border-r border-b small:border-t small:border-r small:border-b small:border-l-0"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-l border-r border-b small:border-l-0 bg-slate-400/10 small:bg-transparent"
                        } 
                        p-[0.85rem]
                        grid grid-cols-1
                        `}
                      >
                        <div className="flex items-center gap-2">
                          <div className="w-1/4 md:w-1/3">
                            <img
                              src={item.iconSrc}
                              alt={item.title}
                              className="w-[100%] h-[100%]"
                            />
                          </div>
                          <h3 className="w-3/4 md:w-2/3 text-tinybase md:text-base font-bold text-primary">
                            {item.title}
                          </h3>
                        </div>
                        <div className="py-2">
                          <p className="text-black text-[0.75rem] xs:text-[0.85rem] small:text-tinybase md:text-[1rem]">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizVisiFlex;
